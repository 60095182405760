import React from 'react';
import { Link } from "gatsby";
import gray from 'gray-percentage';

export default function Header(props) {
  return (
    <div style={{ width: '240px' }}>
      <h1 style={{ fontSize: '14px', paddingBottom: '2px' }}>
        <Link
          style={{
            boxShadow: `none`,
            textDecoration: `none`,
            color: `inherit`,
          }}
          to={`/`}
          >
          {props.title}
        </Link>
      </h1>
      <div style={{ color: gray(80) }}>work in progress.</div>
      <SectionBreak />
      {
        // <React.Fragment>
        //   <HeaderLink to="/projects">projects</HeaderLink>
        //   <HeaderLink to="/essays">essays</HeaderLink>
        //   <HeaderLink to="/books">books</HeaderLink>
        //   <HeaderLink to="/about">about</HeaderLink>
        // </React.Fragment>
      }
    </div>
  );
}

// function HeaderLink(props) {
//   return (
//     <Link
//       style={{
//         boxShadow: 'none',
//         textDecoration: 'none',
//         color: 'inherit',
//       }}
//       {...props}
//       >
//       <div>{props.children}</div>
//     </Link>
//   );
// }

function SectionBreak() {
  return <div style={{ paddingTop: '6px', paddingBottom: '6px' }}>-</div>;
}
