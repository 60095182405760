import React from "react";
import { Link } from 'gatsby';

import Header from './Header';
import Footer from './Footer';

import { rhythm } from "../utils/typography";
import logo from '../../content/assets/ssaber-half.png';

export default class Layout extends React.Component {
  render() {
    const { title, children } = this.props;
    return (
      <div>
        <div style={{ backgroundColor: '#000', height: '10px' }} />
        <Link to='/'>
          <img
            src={logo}
            alt="logo"
            style={{ height: 45, width: 45, position: 'absolute', right: 0, top: '10px' }}
          />
        </Link>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            width: '100%',
            padding: rhythm(1),
          }}
          >
          <main
            style={{
              minHeight: `calc(100vh - 10px - ${rhythm(2)})`,
              maxWidth: rhythm(24),
              paddingRight: '4px'
            }}>
            {children}
          </main>
          <Header title={title} />
        </div>
        <Footer />
      </div>
    );
  }
}
