import React from 'react';

import EmailSignup from './EmailSignup';

import { rhythm } from "../utils/typography";
import gray from 'gray-percentage';

export default function Footer() {
  return (
    <footer
      style={{
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        backgroundColor: '#f7f7f7',
        padding: `${rhythm(3)} ${rhythm(1)}`,
      }}
      >
      <EmailSignup />
      <div style={{ display: 'flex', minWidth: '100px' }}>
        <span style={{ color: gray(80), paddingRight: '15px' }}>
          © {new Date().getFullYear()} ssaber
        </span>
        <HeaderLink href="https://mobile.twitter.com/smkiv" >twitter</HeaderLink>
      </div>
    </footer>
  );
}

function HeaderLink(props) {
  return (
    <a
      style={{
        boxShadow: 'none',
        textDecoration: 'none',
        color: gray(80), 
      }}
      target="_blank"
      rel="noopener noreferrer"
      {...props}
      >
      <div>{props.children}</div>
    </a>
  );
}

