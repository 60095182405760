import React from "react";
import { Link, graphql } from "gatsby";

import Layout from "../components/Layout";
import SEO from "../components/Seo";
import { rhythm, scale } from "../utils/typography";


export default class PostTemplate extends React.Component {
  render() {
    const post = this.props.data.markdownRemark;
    const siteTitle = this.props.data.site.siteMetadata.title;
    const { previous, next, slug } = this.props.pageContext;

    const shareURL = post.frontmatter.mediumLink ?
          post.frontmatter.mediumLink
          : `https://ssaber.com${slug}`;

    const discussUrl = `https://mobile.twitter.com/search?q=${encodeURIComponent(shareURL)}`;

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title={post.frontmatter.title}
          description={post.frontmatter.description || post.excerpt}
          />
        <h1 style={{ marginBottom: 0 }}>{post.frontmatter.title}</h1>
        <p
          style={{
          ...scale(-1 / 5),
          display: `block`,
          marginBottom: rhythm(1),
          }}
          >
          {post.frontmatter.date}
        </p>
        <div dangerouslySetInnerHTML={{ __html: post.html }} />
        <SectionBreak />
        <a href={discussUrl} target="_blank" rel="noopener noreferrer">
          Discuss on Twitter
        </a>
        <ul
          style={{
          display: `flex`,
          flexWrap: `wrap`,
          justifyContent: `space-between`,
          listStyle: `none`,
          padding: 0,
          }}
          >
          <li>
            {previous && (
              <Link to={previous.fields.slug} rel="prev">
                ← {previous.frontmatter.title}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link to={next.fields.slug} rel="next">
                {next.frontmatter.title} →
              </Link>
            )}
          </li>
        </ul>
      </Layout>
    );
  }
}

function SectionBreak() {
  return <div style={{ paddingBottom: '20px' }}>-</div>;
}

export const pageQuery = graphql`
  query PostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        mediumLink
        date(formatString: "MMMM DD, YYYY")
        description
      }
    }
  }
`;
